<template>
  <a-modal
    v-drag-modal
    :title="title"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :maskClosable="false"
    :width="1500"
    :dialog-style="{ top: '20px' }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :bordered="false" class="card-area">
      <div class="search">
        <!-- 搜索区域 -->
        <div class="items" style="flex: none">
          <div class="one-item">
            <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="发布用户名"
              allowClear
            />
          </div>

          <div class="one-item" style="padding-left: 10px">
            <a-input
              class="input"
              v-model="queryParams.content"
              placeholder="评论内容"
              allowClear
            />
          </div>
        </div>
        <div class="operator">
          <a-button type="primary" @click="search(1)">查询</a-button>
        </div>
      </div>
      <div style="margin-bottom: 10px">
        <a-button
          type="danger"
          :disabled="!hasSelected"
          :loading="loading"
          @click="onDelete(selectedRowKeys)"
        >
          删除
        </a-button>
      </div>

      <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :scroll="{ y: 500 }"
        @expand="expand"
        :expandedRowKeys="expandedRowKeys"
      >
        <template
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 0"
        >
          <comment-expan-tab ref="expanTab" />
        </template>
        <template slot="images" slot-scope="text, record">
          <div v-if="text.avatar" class="img-list">
            <el-image
              v-for="item in (text.avatar || '').split(',')"
              :key="item"
              style="width: 40px; height: 40px"
              :src="item | fullImgPath"
              fit="contain"
              :preview-src-list="(text.avatar || '').split(',')"
            />
          </div>
        </template>
        <template slot="images2" slot-scope="text, record">
          <div v-if="record.media" class="img-list">
            <el-image
                v-for="item in JSON.parse(record.media )"
                :key="item"
                style="width: 40px; height: 40px"
                :src="item | fullImgPath"
                fit="contain"
                :preview-src-list="JSON.parse(record.media )"
            />
          </div>
        </template>
        <template slot="childComments" slot-scope="text, record">
          <div
            class="textClick"
            :style="{ color: textColor }"
            @click="expand(!showExpand, text)"
          >
            {{ text.childComments }}
          </div>
        </template>
        <template slot="mode" slot-scope="text, record">
          <a-tag :color="record.mode | dictName('commentMode', 'color')" style="cursor: pointer">
            <span>{{ record.mode| dictName("commentMode") }}</span>
          </a-tag>
        </template>
        <template slot="content" slot-scope="text, record">
          <a-tooltip>
            <template slot="title"> {{ text.content }} </template>
            <div>
              {{
                text.content.length > 30
                  ? text.content.slice(0, 50) + "..."
                  : text.content
              }}
            </div>
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div>
            <a-button
              type="danger"
              @click="onDelete(record)"
            >
              删除
            </a-button>
          </div>
        </template>
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import { ListMixin } from "@/mixin/list-mixin";
import CommentExpanTab from "./CommentExpanTab.vue";
export default {
  name: "ModifyCommentModal",
  mixins: [ListMixin],
  components: { CommentExpanTab },
  data() {
    return {
      listGetUrl: "comment/listByAdmin",
      listMethod: "postJson",
      visible: false,
      showExpand: false,
      title: "",
      confirmLoading: false,
      queryParams: {
        username: "",
        content: "",
      },
      selectedRowKeys: [],
      expandedRowKeys: [],
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "头像",
          width: 100,
          align: "center",
          scopedSlots: { customRender: "images" },
        },
        {
          title: "发布用户名",
          width: 150,
          align: "center",
          dataIndex: "username",
        },
        {
          title: "类型",
          width: 80,
          align: "center",
          dataIndex: "mode",
          scopedSlots: { customRender: "mode" },
        },
        {
          title: "公司/律所名称",
          width: 170,
          align: "center",
          dataIndex: "company",
        },
        {
          title: "内容",
          align: "center",
          width: 150,
          // dataIndex: "content",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "图片",
          align: "center",
          width: 150,
          // dataIndex: "content",
          scopedSlots: { customRender: "images2" },
        },
        {
          title: "评论回复数",
          width: 100,
          align: "center",
          // dataIndex: "childComments",
          scopedSlots: { customRender: "childComments" },
        },
        {
          title: "创建时间",
          width: 100,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          // fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        // {}
      ];
    },
  },
  methods: {
    // 全选、多选
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    showModal(record) {
      this.visible = true;
      this.queryParams.type = 1;
      this.queryParams.postId = record.id;
      this.search();
      this.title = "帖子ID：" + record.id + "评论列表";
    },
    onDelete(record) {
      let msg = "您确定要删除【ID为" + record.id + "】的评论吗？";
      let data = [];
      if (Array.isArray(record)) {
        msg = "确定要删除所选评论吗？";
        data = record;
      } else {
        data = [record.id];
      }
      let that = this;
      this.$confirm({
        title: "删除确认",
        content: msg,
        centered: true,
        onOk() {
          that.$postJson("comment/delete", data).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
    expand(e, record) {
      this.showExpand = e;
      if (!e) {
        this.expandedRowKeys = this.expandedRowKeys.filter(
          (v) => v != record.id
        );
        return;
      }
      // 必须延迟一下，否则读取不到expanTab组件
      if (record.childComments != 0) {
        this.expandedRowKeys.push(record.id);
      }
      if (e && record.childComments == 0) {
        this.$message.error("无回复评论！！！");
        return;
      }
      setTimeout(() => {
        this.$refs.expanTab.showExpanTab(record);
      }, 100);
    },
    handleOk() {
      this.$emit("success");
      this.selectedRowKeys = [];
      this.visible = false;
    },
    handleCancel() {
      this.$emit("cancel");
      this.selectedRowKeys = [];
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}
</style>
